import { Hooks } from 'Components/Hooks';

import {
  Backdrop,
  ViewConnected,
  CallTimer,
  QualityWarning,

  Meters,

  ToggleHoldButton,
  ToggleMuteButton,
  SettingsIconButton,

  Dialpad,

  DisconnectButton,

  ViewConnecting,
  QualityMeterModal,
  MediaErrorModal,
  SettingsModal,

  registerDrawLoop,
} from './UIComponents';

import { DebugView } from './DebugComponents';

export default function RefCallUI({ ctrl }) {
  const hooks = new Hooks();

  const ctx = {
    hooks,
    ctrl,
  };

  const root = (
    <div class="ref-call-ui">
      <Backdrop ctx={ctx} />
      <DebugView ctx={ctx} />
      <ViewConnected ctx={ctx}>
        <CallTimer ctx={ctx} />
        <QualityWarning ctx={ctx} />

        <Meters ctx={ctx} />

        <div class="btn-panel">
          <div class="btn-toolbar-full">
            <ToggleHoldButton ctx={ctx} />
            <ToggleMuteButton ctx={ctx} />
            <SettingsIconButton ctx={ctx} />
          </div>

          <Dialpad ctx={ctx} />
        </div>

        <DisconnectButton ctx={ctx} />
      </ViewConnected>

      <ViewConnecting ctx={ctx} />

      <QualityMeterModal ctx={ctx} />
      <MediaErrorModal ctx={ctx} />
      <SettingsModal ctx={ctx} />
    </div>
  );

  registerDrawLoop(ctrl, root);

  ctrl.on('update', () => hooks.run(ctrl));
  hooks.run(ctrl);

  return root;
}
