import { TimelineGraphView } from 'Components/Graphs/TimelineGraphView';
import { RefFormData, FormText, FormSelect, wrapSubmitHandler } from 'Components/FormComponents';
import { hook, Hooks } from 'Components/Hooks';
import HookedList from 'Components/HookedList';

import s from './strings';

export function DebugView({ ctx: { hooks, ctrl } }) {
  return (
    <div class="debug" use:hook={hooks.show('debugMode')}>
      <div class="debug-status">
        <DebugClientStatus ctrl={ctrl.debugClientStatusController} />
        <DebugCallStats ctrl={ctrl.debugCallStatsController} />
        <DebugWarning ctrl={ctrl.debugWarningStatusController} />
        <DebugDevices ctrl={ctrl} />
      </div>

      <DebugGraphs ctrl={ctrl.debugCallStatsController} />
    </div>
  );
}

export function ConfDebugView({ ctx: { hooks, ctrl } }) {
  return (
    <div class="debug" use:hook={hooks.show('debugMode')}>
      <div class="debug-status">
        <DebugClientStatus ctrl={ctrl.debugClientStatusController} />
        <DebugCallStats ctrl={ctrl.debugCallStatsController} />
        <DebugConfStatus ctrl={ctrl.debugConfStatusController} />
        <DebugConfCommands ctx={{ hooks, ctrl }} />
        <DebugWarning ctrl={ctrl.debugWarningStatusController} />
        <DebugDevices ctrl={ctrl} />
      </div>

      <DebugGraphs ctrl={ctrl.debugCallStatsController} />
    </div>
  );
}

export function DebugClientStatus({ ctrl }) {
  const hooks = new Hooks();

  const root = (
    <div>
      <h5>{s.DebugView.lblClientStatus}</h5>
      <table>
        <TableRows hooks={hooks} fields={ctrl.fields} />
      </table>
    </div>
  );

  ctrl.on('update', () => hooks.run(ctrl));
  hooks.run(ctrl);

  return root;
}

export function DebugCallStats({ ctrl }) {
  const hooks = new Hooks();

  const root = (
    <div>
      <h5>{s.DebugView.lblCallStatus}</h5>
      <table>
        <TableRows hooks={hooks} fields={ctrl.fields} />
      </table>
    </div>
  );

  ctrl.on('update', () => hooks.run(ctrl));
  hooks.run(ctrl);

  return root;
}

export function DebugConfStatus({ ctrl }) {
  const hooks = new Hooks();

  const root = (
    <div>
      <h5>{s.DebugView.lblConferenceStatus}</h5>
      <table>
        <TableRows hooks={hooks} fields={ctrl.fields} />
      </table>
    </div>
  );

  ctrl.on('update', () => hooks.run(ctrl));
  hooks.run(ctrl);

  return root;
}

function TableRows({ hooks, fields }) {
  return Object.keys(fields).map(fieldName => (
    <tr>
      <td>{fieldName}</td>
      <td use:hook={hooks.text(fieldName)}></td>
    </tr>
  ));
}

export function DebugWarning({ ctrl }) {
  let list;

  const root = (
    <div>
      <h5>{s.DebugView.lblWarnings}</h5>

      <HookedList
        ref={list}
        itemKey="name"
        itemElementType="div"
        createItemNode={(name, hooks) => {
          return (
            <div><strong>{name}</strong></div>
          );
        }}
        createEmptyNode={() => <div><strong>{s.DebugView.lblNoActiveWarnings}</strong></div>}
      >
        <div />
      </HookedList>
    </div>
  );

  ctrl.on('update', () => list.render(ctrl.warnings));
  list.render(ctrl.warnings);

  return root;
}

export function DebugDevices({ ctrl }) {
  const hooks = new Hooks();

  const root = (
    <div>
      <h5>{s.DebugView.lblDevices}</h5>
      <code use:hook={hooks.text('devicesRaw', val => JSON.stringify(val, null, 2))} />
    </div>
  );

  ctrl.on('update', () => hooks.run(ctrl));
  hooks.run(ctrl);

  return root;
}

export function DebugGraphs({ ctrl }) {
  return (
    <div>
      <DebugGraph ctrl={ctrl} statName="mos" />
      <DebugGraph ctrl={ctrl} statName="rFactor" />
      <DebugGraph ctrl={ctrl} statName="ppl" />
      <DebugGraph ctrl={ctrl} statName="rtt" />
    </div>
  );
}

export function DebugGraph({ ctrl, statName }) {
  let div;
  let canvas;

  const root = (
    <>
      <div>{statName}</div>
      <div ref={div}>
        <canvas ref={canvas}/>
      </div>
    </>
  );

  const graphView = new TimelineGraphView(div, canvas);

  ctrl.on('update', () => {
    graphView.updateEndDate(ctrl.timestamp);
  });

  graphView.addDataSeries(ctrl.dataSeries[statName]);
  // set graph endDate to current timestamp and force repaint
  graphView.updateEndDate();
  graphView.repaint(true);

  return root;
}

export function DebugConfCommands({ ctx: { hooks, ctrl } }) {
  const form = new RefFormData();

  hooks.add('host', host => {
    const selected = form.get('command');

    const commandInput = form.getInput('command');
    commandInput.textContent = '';
    commandInput.append(...(
      ctrl.availableCommands.map(cmd =>
        <option value={cmd.name} selected={cmd.name === selected}>{cmd.desc}</option>
      )
    ));
  });

  return (
    <form onsubmit={wrapSubmitHandler(() => ctrl.sendConfCommand(form.get('command'), form.get('value')))}>
      <FormSelect inline form={form} name="command" label={s.DebugView.lblCommand} />
      <FormText inline form={form} name="value" label={s.DebugView.lblValue} />
      <button type="submit" class="btn btn-primary">{s.DebugView.lblSend}</button>
    </form>
  );
}
