import 'shims';

import EventEmitter from 'events';

import { initStrings } from 'LoaderStrings';

import { isWebRtcSupported } from './BrowserSupport';
import { RefAppController } from './RefAppController';
import EmbeddedRefAppUI from './EmbeddedRefAppUI';

const REF_LIB_DEFAULT_UI_TYPE = 'compact';

export default class EmbeddedRefLib {
  static placeCall({ element, config, uiType = null }) {
    if (this.isActive)
      throw new WebCallEmbeddedRefLibError('a call is already in progress');

    this._init({ element, uiType });

    this._ctrl.initiateConnection(this._ctrl.callController, config, config => config);
  }

  static openSettings({ element, uiType = null }) {
    this._init({ element, uiType });

    this._ctrl.openSettings();
  }

  static initStrings({ language = null, overrides = null, replaces = null }) {
    this._throwIfUnsupported();

    if (this._initStringsDone)
      throw new WebCallEmbeddedRefLibError('strings have already been inititalized');

    if (language && ![ 'EN', 'ES', 'FR', 'JP' ].includes(language))
      throw new WebCallEmbeddedRefLibError('invalid language');

    if (overrides && typeof overrides !== 'object')
      throw new WebCallEmbeddedRefLibError('overrides must be object');

    if (replaces) {
      if (!Array.isArray(replaces))
        throw new WebCallEmbeddedRefLibError('replaces must be array');
      replaces.forEach((repl, idx) => {
        if (!(typeof repl.old === 'string' && typeof repl.new === 'string'))
          throw new WebCallEmbeddedRefLibError(`replaces[${idx}] missing valid 'old' or 'new'`);
      });
    }

    initStrings(language, overrides ? { WebCall: overrides } : null, replaces);

    this._initStringsDone = true;
  }

  static on(type, listener) {
    this._throwIfUnsupported();
    this._initEmitter();

    if (![ 'active', 'inactive' ].includes(type))
      throw new WebCallEmbeddedRefLibError('invalid type');

    this._emitter.on(type, listener);
  }

  static isSupported() {
    return isWebRtcSupported();
  }

  static get isActive() {
    if (!this._ctrl)
      return false;

    return !!(
      this._ctrl.isActive ||
      this._ctrl.callController.isSettingsOpen ||
      this._ctrl.connectionErrorMessage ||
      this._ctrl.callController.mediaErrorMessage
    );
  }

  static get isConnected() {
    if (!this._ctrl)
      return false;

    return this._ctrl.isConnected;
  }

  static _init({ element, uiType }) {
    this._throwIfUnsupported();
    this._initEmitter();

    if (uiType) {
      if (![ 'compact', 'full' ].includes(uiType))
        throw new WebCallEmbeddedRefLibError('invalid uiType');

      if (this._uiType && this._uiType !== uiType)
        throw new WebCallEmbeddedRefLibError('uiType can not be changed after first call to EmbeddedRefLib');
    }
    this._uiType = uiType || REF_LIB_DEFAULT_UI_TYPE;

    if (!this._initStringsDone)
      initStrings();
    this._initStringsDone = true;

    if (!this._ctrl) {
      this._ctrl = new RefAppController({});

      this._isActiveLast = false;
      this._ctrl.on('update', () => {
        const { isActive } = this;

        if (this._isActiveLast !== isActive) {
          this._isActiveLast = isActive;
          this._emitter.emit(isActive ? 'active' : 'inactive');
        }
      });
    }

    if (!this._uiElement) {
      this._uiElement = <EmbeddedRefAppUI ctrl={this._ctrl} uiType={this._uiType} />;
      element.appendChild(this._uiElement);
    }
  }

  static _initEmitter() {
    if (!this._emitter) {
      this._emitter = new EventEmitter();
    }
  }

  static _throwIfUnsupported() {
    if (!isWebRtcSupported())
      throw new WebCallEmbeddedRefLibError('browser is not supported');
  }
}

class WebCallEmbeddedRefLibError extends Error {
  constructor(message) {
    super(message);
    this.name = 'WebCallEmbeddedRefLibError';
  }
}
