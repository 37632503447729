import { AppController } from './AppController';
import { CallController } from './CallController';

export class RefAppController extends AppController {
  constructor({
    localStorageKey,
    storedFields = null,
  }) {
    super({
      localStorageKey,
      storedFields: storedFields || [
        'wsSipURI',
        'toSipURI',
        'fromSipURI',
        'fromName',
      ],
    });

    this.callController = new CallController({
      instanceName: 'RefApp',
    });
    this.addCallController(this.callController);

    this.callController.on('update', () => this.emit('update'));
  }

  connect(formData) {
    this.initiateConnection(this.callController, formData, formData => this._getConnectParams(formData));
  }

  _getConnectParams(formData) {
    const ret = {
      wsSipURI: formData.wsSipURI,
      fetchLocationURL: this._appConfig.fetchLocationURL,
      fetchRTCConfigurationURL: this._appConfig.fetchRTCConfigurationURL,

      toSipURI: formData.toSipURI,
      fromSipURI: formData.fromSipURI || this._appConfig.fromSipURI,

      fromName: formData.fromName,
    };

    if (this._debugMode) {
      ret.fetchLocationURL = formData.fetchLocationURL;
      ret.fetchRTCConfigurationURL = formData.fetchRTCConfigurationURL;
    }

    return ret;
  }
}
