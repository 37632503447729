import { Hooks } from 'Components/Hooks';

import {
  AppConnectionErrorModal,
} from './UIComponents';
import RefCallUI from './RefCallUI';
import EmbeddedRefCallUI from './EmbeddedRefCallUI';

export default function EmbeddedRefAppUI({ ctrl, uiType }) {
  const hooks = new Hooks();

  const ctx = {
    hooks,
    ctrl,
  };

  const isCompact = uiType === 'compact';

  const root = (
    <div
      class="pnlWebCallApp"
      class:webcall-compact={isCompact}
    >
      <AppConnectionErrorModal ctx={ctx} anchored={uiType === 'full'} />

      {isCompact ? <EmbeddedRefCallUI ctrl={ctrl.callController} /> : <RefCallUI ctrl={ctrl.callController} />}
    </div>
  );

  ctrl.on('update', () => hooks.run(ctrl));
  hooks.run(ctrl);

  return root;
}
